import { useQuery } from "@apollo/client"
import { GET_MY_PERMISSIONS } from "../graphql/queries"
import { setPermissions } from "../redux/auth/authSlice"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { IGetMyPermissionsResponse } from "../types/graphqlResponse"
import { useAuthStatus } from "./useAuthStatus"

const useGetMyPermissions = () => {
  const dispatch = useAppDispatch()
  const { loggedIn } = useAuthStatus()
  const { loading, error, client } = useQuery<IGetMyPermissionsResponse>(
    GET_MY_PERMISSIONS,
    {
      onCompleted: (data) => {
        dispatch(setPermissions(data.userPermissions))
      },
      skip: !loggedIn,
      fetchPolicy: "cache-first",
    }
  )

  const permissions = useAppSelector((state) => state.auth.permissions)

  return { permissions, loading, error, client }
}

export default useGetMyPermissions
