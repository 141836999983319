import { createGlobalStyle } from "styled-components"
import globals from "../../constants/globals"

const { PRIMARY_COLOR, SECONDARY_COLOR } = globals

const GlobalStyles = createGlobalStyle`

  .cursor-pointer {
    cursor: pointer
  }

  .cursor-default {
    cursor: default;
  }
  
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: ${PRIMARY_COLOR};
    border-bottom: 1px solid ${PRIMARY_COLOR};
  }

  .nav-link {
    &:hover,
    .active {
      color: ${PRIMARY_COLOR};
      border-bottom: 1px solid ${PRIMARY_COLOR};
    
    div {
      // font-weight: 600;
      // padding-left: 5px;
         border-bottom: 1px solid #ffffff;
    }
}
    @media (max-width: 992px) {
      a {
        padding-left: 10px;
      }
    }
  }


  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #eee;
  }


  .dropdown-navbar {
  li a {
      font-family: 'Mulish', sans-serif;
      text-decoration: none;
      font-weight: 500;
    }
  }

  body {
    font-family: 'Mulish', sans-serif !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Mulish', sans-serif;
    color: ${SECONDARY_COLOR};
  }

  .custom-link {
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    text-decoration: underline;
    &:hover, &:focus {
      color: ${SECONDARY_COLOR};
      text-decoration: underline;
    }
  }

  /* Edit Claim Modal Styles */
  .edit-claim-modal {
    min-width: 70%;
    @media screen and (max-width: 992px) {
      min-width: 90%;
    }
  }

  /* Announcement Modal Styles */

  .react-quill-style {
    .ql-editor {
      min-height: 150px;
    }
    margin-top: 0.5rem;
  }

  .close-icon {
    cursor: pointer;
    font-size: 2rem;
    color: #666;
  }

// Color

.bg-sky3 {
  background-color: #f0f4fa;
}

  /** Layout */
  #root {
    height: 100%;
  }

  .vh-fill {
    min-height: calc(100vh - 10rem);
  }

  .vh-250 {
    min-height: calc(100vh - 250px);
  }

  .vh-200 {
    min-height: calc(100vh - 200px);
  }

  .vh-150 {
    min-height: calc(100vh - 150px);
  }

  .fs-14 {
    font-size: 14px;
  }
`

export default GlobalStyles
