import { gql } from "@apollo/client"

// Consumer Side
const CREATE_ENROLLMENT = gql`
  mutation CreateEnrolment($enrolment: enrolmentInput) {
    createEnrolment(enrolment: $enrolment)
  }
`

const VALIDATE_ENROLLMENT_FORM = gql`
  mutation ValidateEnrolment($workEmail: String) {
    validateEnrolment(workEmail: $workEmail)
  }
`

const AUTOCOMPLETE_ADDRESS = gql`
  mutation AutoCompleteAddress($dealerStreetAddress: String!) {
    autoCompleteAddress(dealerStreetAddress: $dealerStreetAddress) {
      dealerStreetAddress
      secondary
      dealerCity
      dealerState
      dealerZip
    }
  }
`

const VALIDATE_ADDRESS = gql`
  mutation ValidateAddress(
    $dealerStreetAddress: String
    $dealerCity: String
    $dealerState: String
    $dealerZip: String
    $secondary: String
  ) {
    validateAddress(
      dealerStreetAddress: $dealerStreetAddress
      dealerCity: $dealerCity
      dealerState: $dealerState
      dealerZip: $dealerZip
      secondary: $secondary
    )
  }
`

const CLAIM_SALE = gql`
  mutation CreateClaim($claim: ClaimInput) {
    createClaim(claim: $claim)
  }
`

const VALIDATE_CLAIM_SALE = gql`
  mutation ValidateClaim($claim: ClaimInput) {
    validateClaim(claim: $claim)
  }
`

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profile: UpdateProfileInput) {
    updateProfile(profile: $profile)
  }
`

// Common
const SUBMIT_CONTACT_FORM = gql`
  mutation ContactUs($input: ContactUsInput) {
    contactUs(input: $input)
  }
`

const SIGNIN = gql`
  mutation SignIn(
    $userName: String
    $password: String
    $signingInFromConsumerOrAdmin: SigningInFromConsumerOrAdmin
  ) {
    signIn(
      userName: $userName
      password: $password
      signingInFromConsumerOrAdmin: $signingInFromConsumerOrAdmin
    ) {
      permissions
      token
      userName
      userType
      refreshToken
    }
  }
`

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput) {
    updatePassword(input: $input)
  }
`

// Admin Side
const DEALER_UPLOAD = gql`
  mutation DealerUploader($file: Upload!) {
    dealerUploader(file: $file)
  }
`

const CREATE_PRODUCTS_WITH_INCENTIVE = gql`
  mutation CreateProduct($input: ProductInput) {
    createProduct(input: $input)
  }
`

const UPDATE_INCENTIVE = gql`
  mutation UpdateRewards($product: UpdateRewardsInput) {
    updateRewards(product: $product)
  }
`

const VALIDATE_ADD_INCENTIVE = gql`
  mutation ValidateProductAndReward($product: ProductInput) {
    validateProductAndReward(product: $product)
  }
`

const CREATE_USER = gql`
  mutation CreateUser($user: UserInput) {
    createUser(user: $user)
  }
`

const CREATE_USER_ROLE = gql`
  mutation CreateUserRole($userRole: UserRoleInput) {
    createUserRole(userRole: $userRole)
  }
`

const UPDATE_CLAIM = gql`
  mutation UpdateClaim($claim: UpdateClaimInput) {
    updateClaim(claim: $claim)
  }
`

const VALIDATE_UPDATE_CLAIM = gql`
  mutation ValidateClaimUpdates($claim: UpdateClaimInput) {
    validateClaimUpdates(claim: $claim)
  }
`

const PASSWORD_RESET = gql`
  mutation PasswordResetMail(
    $userEmail: String!
    $signingInFromConsumerOrAdmin: SigningInFromConsumerOrAdmin!
  ) {
    passwordResetMail(
      userEmail: $userEmail
      signingInFromConsumerOrAdmin: $signingInFromConsumerOrAdmin
    )
  }
`

const UPDATE_USER = gql`
  mutation Mutation($input: UpdateUserInput) {
    updateUser(input: $input)
  }
`

const DELETE_INCENTIVE = gql`
  mutation DeleteRewards($incentiveId: String!, $productId: String!) {
    deleteRewards(incentiveId: $incentiveId, productId: $productId)
  }
`

const CREATE_UPDATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($announcement: AnnouncementInput) {
    createAnnouncement(announcement: $announcement)
  }
`

const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId)
  }
`

const CREATE_HEADER = gql`
  mutation CreateHeader($header: HeaderInput) {
    createHeader(header: $header)
  }
`

const CREATE_EMAIL = gql`
  mutation CreateEmail($email: EmailDetailsInput) {
    createEmail(email: $email)
  }
`

const CREATE_MESAAGE = gql`
  mutation Mutation($message: MessageDetailsInput) {
    createMessage(message: $message)
  }
`

const APPROVE_MULTIPLE_CLAIMS = gql`
  mutation ApproveMultipleClaims($claimIds: [ID]) {
    approveMultipleClaims(claimIds: $claimIds)
  }
`

const TOGGLE_PARTICIPANT_STATUS = gql`
  mutation ToggleParticipantStatus(
    $isActive: Boolean
    $participantIds: [String]
  ) {
    toggleParticipantStatus(
      isActive: $isActive
      participantIds: $participantIds
    )
  }
`

const SELECT_REWARD_TYPE = gql`
  mutation SelectRewardType($input: SelectRewardTypeInput) {
    selectRewardType(input: $input)
  }
`
const GET_OTP = gql`
  mutation SendOTPVerificationCode($phone: String) {
    sendOTPVerificationCode(phone: $phone)
  }
`

const DELETE_PRODUCT = gql`
  mutation DeleteIncentive($incentiveID: String) {
    deleteIncentive(incentiveID: $incentiveID)
  }
`

export {
  SUBMIT_CONTACT_FORM,
  CREATE_ENROLLMENT,
  VALIDATE_ENROLLMENT_FORM,
  AUTOCOMPLETE_ADDRESS,
  VALIDATE_ADDRESS,
  SIGNIN,
  DEALER_UPLOAD,
  CREATE_PRODUCTS_WITH_INCENTIVE,
  UPDATE_INCENTIVE,
  VALIDATE_ADD_INCENTIVE,
  CLAIM_SALE,
  VALIDATE_CLAIM_SALE,
  CREATE_USER,
  CREATE_USER_ROLE,
  UPDATE_PASSWORD,
  UPDATE_CLAIM,
  VALIDATE_UPDATE_CLAIM,
  PASSWORD_RESET,
  UPDATE_USER,
  DELETE_INCENTIVE,
  CREATE_UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  CREATE_HEADER,
  CREATE_EMAIL,
  UPDATE_PROFILE,
  CREATE_MESAAGE,
  APPROVE_MULTIPLE_CLAIMS,
  TOGGLE_PARTICIPANT_STATUS,
  SELECT_REWARD_TYPE,
  GET_OTP,
  DELETE_PRODUCT,
}
