import globals from "../../constants/globals"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import HeroSection from "../styled/HeroSection.styled"

const { PROGRAM_NAME } = globals

const SelectRewardSuccess = () => {
  const { header } = useGetProgramHeader()

  return (
    <HeroSection
      mutedText={PROGRAM_NAME}
      heading="You have redeemed the payment, you will recieve the reward in next 10-15 business days"
      description={header}
    />
  )
}

export default SelectRewardSuccess
