import globals from "../../constants/globals"
import styled from "styled-components"

const Footer = () => {
  const {
    PRIVACY_POLICY_URL,
    TERMS_AND_CONDITIONS_URL,
    POWERED_BY_URL,
    POWERED_BY_NAME,
    PROGRAM_NAME,
  } = globals

  return (
    <FooterContainer>
      <div>
        <div>
          <a href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">
            Privacy Policy
          </a>
        </div>
        <div>
          <a href={TERMS_AND_CONDITIONS_URL} rel="noreferrer" target="_blank">
            Terms of Service
          </a>
        </div>
      </div>
      <div>© {PROGRAM_NAME}</div>
      <div>
        <a href={POWERED_BY_URL} rel="noreferrer" target="_blank">
          Powered By {POWERED_BY_NAME}
        </a>
      </div>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  text-transform: uppercase;
  background-color: #f0f4fa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.8rem;
  padding: 2.5rem;

  @media screen and (max-width: 996px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  div:first-child {
    display: flex;
    gap: 1rem;
  }

  div {
    a {
      text-decoration: none;
      color: #000;
    }
  }
`

export default Footer
