import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { IPermission } from "../../types/permission"

const initialState: { permissions: IPermission[] } = {
  permissions: [],
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPermissions: (state, { payload }: PayloadAction<IPermission[]>) => {
      state.permissions = [...payload]
    },
  },
})

export const { setPermissions } = authSlice.actions

export default authSlice.reducer
