const globals = {
  IS_ADMIN: process.env.REACT_APP_IS_ADMIN?.trim() === "true" ? true : false,
  TITLE: process.env.REACT_APP_TITLE,
  BRAND_LOGO_HEIGHT: process.env.REACT_APP_BRAND_LOGO_HEIGHT,
  PRIMARY_COLOR: process.env.REACT_APP_PRIMARY_COLOR,
  SECONDARY_COLOR: process.env.REACT_APP_SECONDARY_COLOR,
  SECONDARY_ANCHOR_BG_COLOR: process.env.REACT_APP_SECONDARY_COLOR,
  PROGRAM_NAME: process.env.REACT_APP_PROGRAM_NAME,
  DEALER_UPLOADER_SAMPLE: process.env.REACT_APP_DEALER_UPLOADER_SAMPLE,
  BPS_MYACCOUNT_HOME: process.env.REACT_APP_BPS_MYACCOUNT_HOME,
  AUTO_LOGOUT_TIME: Number(process.env.REACT_APP_AUTO_LOGOUT_TIME),

  // Footer Related
  TERMS_AND_CONDITIONS_URL: process.env.REACT_APP_TERMS_AND_CONDITIONS_URL,
  PRIVACY_POLICY_URL: process.env.REACT_APP_PRIVACY_POLICY_URL,
  POWERED_BY_URL: process.env.REACT_APP_POWERED_BY_URL,
  POWERED_BY_NAME: process.env.REACT_APP_POWERED_BY_NAME,

  // Contact Us Page
  CONTACTUS_NUMBER: process.env.REACT_APP_CONTACTUS_NUMBER,
  CONTACTUS_TIMINGS: process.env.REACT_APP_CONTACTUS_TIMINGS,
  CARDHOLDER_SERVICES_CONTACT:
    process.env.REACT_APP_CARDHOLDER_SERVICES_CONTACT,
  CARDHOLDER_SERVICES_EMAIL: process.env.REACT_APP_CARDHOLDER_SERVICES_EMAIL,

  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  GRAPHQL_SUBSCRIPTION_ENDPOINT: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT,

  //program communication labels
  FIRST_MULTI_SELECT: process.env.REACT_APP_PROGRAM_COMMUNICATION_FIRST_SELECT,
  SECOND_MULTI_SELECT: process.env.REACT_APP_PROGRAM_COMMUNICATION_SECOND_SELECT,
  THIRD_MULTI_SELECT: process.env.REACT_APP_PROGRAM_COMMUNICATION_THIRD_SELECT,
}

export default globals
