import { useNavigate } from "react-router-dom"
import globals from "../../constants/globals"
import { CommonPaths } from "../../constants/paths"
import { SecondaryButton } from "../styled/Button.styled"

const { PROGRAM_NAME } = globals
const { CURRENT_INCENTIVES_PATH } = CommonPaths

const EnrollmentSuccess = () => {
  return (
    <>
      <div className="row justify-content-center text-center">
        <div className="col-4">
          <h4>{PROGRAM_NAME}</h4>
          <h1>You Have Been Enrolled!</h1>
        </div>
      </div>
      <NavigationLinks />
    </>
  )
}

const NavigationLinks = () => {
  const navigate = useNavigate()

  const goToProductIncentive = () => navigate(CURRENT_INCENTIVES_PATH)

  return (
    <div className="container mx-auto row justify-content-center mb-5 text-center">
      <div className="col-lg-8 col-11 px-3 py-4 p-md-5 border">
        <div className="row justify-content-center gap-3 gap-md-0">
          <div className="col-12 text-center mb-4">
            You can now view the current incentives
          </div>

          <div className="col-md-6">
            <SecondaryButton type="button" onClick={goToProductIncentive}>
              View Current Incentives
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentSuccess
