import styled from "styled-components"

const Button = styled.button.attrs(() => ({
  className: "btn",
}))`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  border-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: rounded;
  color: #fff;
  font-weight: 500;
  
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.secondaryColor};
    border-color: ${({ theme }) => theme.colors.secondaryColor};
    color: #fff;
  }

  &:disabled {
    background-color: #ccc;
    border-color: #ccc;
  }
`

const SecondaryButton = styled.button.attrs(() => ({
  className: "btn",
}))`
  background-color: #fff;
  color: #777;
  border: 1px solid #aaa;
  border-radius: rounded;
  font-weight: 500;
  &:hover,
  &:focus {
    background-color: #eee;
    border: 1px solid #eee;
  }
    &:disabled {
    background-color: #eee;
    border: 1px solid #eee;
    color: #eee;
  }
`


const ApproveButton = styled.button.attrs(() => ({
  className: "btn btn-outline-success",
}))`
  margin: auto .5rem;
`

const DeclineButton = styled.button.attrs(() => ({
  className: "btn btn-outline-danger",
}))`
  margin: auto .5rem;
`

export default Button

export { SecondaryButton, ApproveButton, DeclineButton }
