import { Form, Alert, Divider, UploadFile, Row, Col, Radio } from "antd"
import { useState } from "react"
import {
  SELECT_REWARD_TYPE,
  VALIDATE_ADDRESS,
} from "../../graphql/mutations"
import { useMutation, useQuery } from "@apollo/client/react"
import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import globals from "../../constants/globals"
import HeroSection from "../../components/styled/HeroSection.styled"
import Spinner from "../../components/Spinner"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import LegalFirstName from "../../components/EnrollmentForm/LegalFirstName"
import LegalLastName from "../../components/EnrollmentForm/LegalLastName"
import GoesByName from "../../components/EnrollmentForm/GoesByName"
import JobTitle from "../../components/EnrollmentForm/JobTitle"
import WorkEmail from "../../components/EnrollmentForm/WorkEmail"
import PhoneNumber from "../../components/EnrollmentForm/PhoneNumber"
import Address2 from "../../components/EnrollmentForm/Address2"
import City from "../../components/EnrollmentForm/City"
import State from "../../components/EnrollmentForm/State"
import ZIP from "../../components/EnrollmentForm/ZIP"
import AddressAutocomplete from "../../components/EnrollmentForm/AddressAutocomplete"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import trimFormValues from "../../utils/trimFormValues"
import { useLocation, useNavigate } from "react-router-dom"
import {
  GET_ENROLMENT_DETAIL_BY_TOKEN,
} from "../../graphql/queries"
import { CommonPaths } from "../../constants/paths"
import { rewardTypes } from "../../constants/selectRewardTypeForm"
import visaImage from "../../assets/images/SunPower Visa Blue.png"
import virtualImag from "../../assets/images/SunPower Visa Silver.png"
import styled from "styled-components"
import SelectRewardSuccess from "../../components/SelectRewardForm/SelectRewardSuccess"

const { PROGRAM_NAME } = globals

const { SIGNOUT_PATH } = CommonPaths

// Define styled components
const RewardOption = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
`

const RewardImage = styled.img`
  max-width: 200px;
  margin: 10px 0;
`

type AdditionalData = {
  enrolmentId: string
  claimId: string
}

const SelectRewardTypeForm = () => {
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [isPersonalAddressValidated, setIsPersonalAddressValidated] =
    useState(true)
  const [additionalData, setAdditionalData] = useState<AdditionalData>({
    enrolmentId: "",
    claimId: "",
  })
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("token")

  if (!token) {
    navigate(SIGNOUT_PATH)
  }

  // query to get the enrolment by token
  const { loading: participantLoading, error: errorTokenDetails } = useQuery(
    GET_ENROLMENT_DETAIL_BY_TOKEN,
    {
      variables: {
        token,
      },
      skip: !token,
      onCompleted: (data) => {
        const result = data?.getEnrolmentDetailByToken
        setAdditionalData({
          enrolmentId: result.enrolment._id,
          claimId: result.claimId,
        })

        form.setFieldsValue({
          jobTitle: result.enrolment.jobTitle,
          firstName: result.enrolment.firstName,
          lastName: result.enrolment.lastName,
          goesByName:
            result.enrolment.firstName + " " + result.enrolment.lastName,
          workEmail: result.enrolment.workEmail,
          street1: result.enrolment.street1,
          city: result.enrolment.city,
          state: result.enrolment.state,
          zip: result.enrolment.zip,
          phone: result.enrolment.phone,
        })
      },
    }
  )

  // Mutation for selecting a rewardtype
  const [
    selectRewardType,
    {
      data: successSelectReward,
      loading: selectRewardTypeLoading,
      error: errorSelectRewardType,
      reset,
    },
  ] = useMutation(SELECT_REWARD_TYPE)

  // Mutation for validating addresses
  const [
    validateAddress,
    { reset: validateReset2, error: validateError2, loading: validateLoading2 },
  ] = useMutation<{ validateAddress: "true" | "false" }>(VALIDATE_ADDRESS)

  const { header } = useGetProgramHeader()

  const onFinish = (values: any) => {
    reset()
    validateReset2()

    if (!readOnly) {
      return validateAndToggleReadOnly()
    }

    // Trim whitespace from values
    const trimmedValues = trimFormValues(values)

    const forApi = {
      input: {
        ...trimmedValues,
        claimId: additionalData.claimId,
        enrolmentId: additionalData.enrolmentId,
      },
    }
    selectRewardType({
      variables: forApi,
      onError: toggleReadOnly,
    })
  }

  const toggleReadOnly = (): void => setReadOnly((val) => !val)

  const validateAndToggleReadOnly = () => {
    const personalAddressVariables = {
      dealerStreetAddress: form.getFieldValue("street1"),
      dealerCity: form.getFieldValue("city"),
      dealerState: form.getFieldValue("state"),
      dealerZip: form.getFieldValue("zip"),
      secondary: form.getFieldValue("street2"),
    }

    // Validate Personal Address
    validateAddress({
      variables: personalAddressVariables,
      onCompleted: (data) => {
        setIsPersonalAddressValidated(data.validateAddress === "true")

        if (data.validateAddress === "true") {
          window.scrollTo(0, 500)
          toggleReadOnly()
        }
      },
    })
  }

  if (successSelectReward) {
    return (
      <>
        <Header />
        <div className="vh-150">
          <SelectRewardSuccess />
        </div>
        <Footer />
      </>
    )
  }

  if (participantLoading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <Spinner />
      </div>
    )
  }
  return (
    <>
      <Header />
      <div className="vh-250">
        <HeroSection
          mutedText={PROGRAM_NAME}
          heading="Select Reward Type"
          description={header}
        />

        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-11 border p-md-5 p-3">
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                disabled={readOnly}
                requiredMark={false}
              >
                <Divider />

                <h5>1. Personal Contact Information</h5>

                <div className="row">
                  {[
                    <LegalFirstName disabled={true} />,
                    <LegalLastName disabled={true} />,
                    <GoesByName />,
                    <JobTitle />,
                    <WorkEmail disabled={true} />,
                    // <PhoneNumber />,
                    // For Address 1
                    <AddressAutocomplete
                      form={form}
                      name="street1"
                      label="Address 1"
                      formItemNamesToUpdate={[
                        "street2",
                        "city",
                        "state",
                        "zip",
                      ]}
                      requiredMessage="Please enter an address"
                      disabled={readOnly}
                    />,
                    <Address2 />,
                    <City />,
                  ].map((item, index) => (
                    <div className="col-sm-6" key={index}>
                      {item}
                    </div>
                  ))}

                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col">
                        <State />
                      </div>
                      <div className="col">
                        <ZIP />
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />

                <Row>
                  <Col>
                    <h5>2. Select Your Payment Type:</h5>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Form.Item
                      label="Reward Type"
                      name="rewardType"
                      rules={[
                        {
                          required: true,
                          message: "Reward type is required",
                        },
                      ]}
                    >
                      <Radio.Group className="d-flex justify-content-around">
                        <RewardOption md={6}>
                          <Radio
                            value={rewardTypes.virtual}
                            //  disabled={virtualVisaDisable || disable}
                          >
                            Virtual Visa
                            <RewardImage src={visaImage} />
                          </Radio>
                        </RewardOption>
                        <RewardOption md={6}>
                          <Radio
                            value={rewardTypes.physical}
                            //  disabled={PhyVisaDisable || disable}
                          >
                            Physical Visa
                            <RewardImage src={virtualImag} />
                          </Radio>
                        </RewardOption>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              {/* Spinner */}
              <div className="row">
                {(selectRewardTypeLoading || validateLoading2) && <Spinner />}
              </div>

              {/* Info Message For Address Validation */}

              {!isPersonalAddressValidated && (
                <div className="col-12 mb-3">
                  <Alert
                    type="error"
                    message="Please enter correct personal address"
                  />
                </div>
              )}

              {/* Error Messages */}
              {errorTokenDetails && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={errorTokenDetails.message} />
                </div>
              )}
              {errorSelectRewardType && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={errorSelectRewardType.message} />
                </div>
              )}

              {validateError2 && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={validateError2.message} />
                </div>
              )}
              <div className="row justify-content-center mt-3">
                {readOnly && (
                  <div className="col-md-6 mb-3 mb-md-3">
                    <SecondaryButton
                      type="button"
                      onClick={toggleReadOnly}
                      disabled={selectRewardTypeLoading || validateLoading2}
                    >
                      Go Back
                    </SecondaryButton>
                  </div>
                )}
                <div className="col-md-6">
                  <Button
                    type="submit"
                    onClick={form.submit}
                    disabled={selectRewardTypeLoading || validateLoading2}
                  >
                    Redeem Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SelectRewardTypeForm
