import { MutationResult } from "@apollo/client"
import { Alert } from "antd"
import Spinner from "../Spinner"
import Button from "../styled/Button.styled"

type FooterProps = {
  result: MutationResult<any>
}

const AnnouncementFooter = ({ result }: FooterProps) => {
  return (
    <div className="row justify-content-center border-top">
      <div className="col-12 pb-3">
        {result.loading && <Spinner />}

        {result.error && <Alert type="error" message={result.error.message} />}
      </div>

      <div className="col text-center">
        <Button type="submit" disabled={result.loading}>
          Publish
        </Button>
      </div>
    </div>
  )
}

export default AnnouncementFooter
