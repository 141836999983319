import { Upload, Form, Alert } from "antd"
import type { UploadProps } from "antd"
import { ReactNode, useState } from "react"
import { UploadChangeParam } from "antd/es/upload"
import { Rule } from "antd/es/form"
import { SecondaryButton } from "./styled/Button.styled"

const acceptFileTypesArray = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
]

type IFileUploadProps = {
  setBlockSubmit?: React.Dispatch<React.SetStateAction<boolean>>
  rules?: Rule[]
  acceptedFormats?: string[]
  fileSizeLessThan?: number
  wrongFileFormatMessage?: string
  label?: ReactNode
  buttonLabel?: string
  name?: string
  requiredMessage?: string
  disabled?: boolean
  maxFileCount?: number
}

// The default values are mainly for Enrollment File upload
const FileUpload = ({
  setBlockSubmit,
  rules,
  acceptedFormats = acceptFileTypesArray,
  fileSizeLessThan = 10,
  wrongFileFormatMessage = "Please add file in accepted formats: .jpg, .jpeg, .png and .pdf",
  label = "Please upload a test copy of your sales receipt in order to process your sales claims faster.",
  buttonLabel = "Upload Receipt",
  name = "document",
  /** There is a bug in uploader integration with `Form.Item`.
   * When we are using `rules`, and we remove the file from the uploader,
   * It stops giving us the error and let's submit the form.
   * This `requiredMessage` comes in use then.
   */
  requiredMessage,
  disabled,
  maxFileCount = 1,
}: IFileUploadProps) => {
  const [beforeUploadError, setBeforeUploadError] = useState<string>("")

  const uploadProps: UploadProps = {
    maxCount: maxFileCount,
    multiple: maxFileCount > 1,
    accept: acceptedFormats.join(","),
    beforeUpload: () => false,
    onChange: (file: UploadChangeParam) => {
      // `requiredMessage` prop comment above
      if (file.fileList.length < 1) {
        if (requiredMessage) {
          setBlockSubmit?.(true)
          return setBeforeUploadError(requiredMessage)
        }
        setBlockSubmit!(false)
        return setBeforeUploadError("")
      }

      // Check if file already exists
      // This function loops through the fileList array and finds one with same name and size and throws error
      const fileWithSameNameAndSize =
        file.fileList.find(
          (file, index, self) =>
            index !==
            self.findIndex((t) => t.name === file.name && t.size === file.size)
        )?.name || ""
      if (!!fileWithSameNameAndSize) {
        setBlockSubmit?.(true)
        return setBeforeUploadError(
          `File already exists (${fileWithSameNameAndSize}) with same name and size`
        )
      }

      // Check for file type
      // This function loops through the fileList array and finds one with wrong file format and throws error
      if (
        file.fileList.some(
          (file) => !acceptedFormats.includes(file.originFileObj?.type || "")
        )
      ) {
        setBlockSubmit?.(true)
        return setBeforeUploadError(wrongFileFormatMessage)
      }

      // Check for file size
      // This function loops through the fileList array and finds one with wrong file size and throws error
      const fileWithSizeGreaterThanTen: string =
        file.fileList.find((file) =>
          file.originFileObj?.size
            ? file.originFileObj?.size / 1024 / 1024 > fileSizeLessThan
            : false
        )?.originFileObj?.name || ""
      if (!!fileWithSizeGreaterThanTen) {
        setBlockSubmit!(true)
        return setBeforeUploadError(
          `Please add a file less than ${fileSizeLessThan} MB (${fileWithSizeGreaterThanTen})`
        )
      }

      setBlockSubmit?.(false)
      setBeforeUploadError("")
    },
    disabled,
  }

  return (
    <>
      <Form.Item name={name} label={label} rules={rules} valuePropName="file">
        <Upload {...uploadProps}>
          <SecondaryButton type="button" disabled={disabled}>
            {buttonLabel}
          </SecondaryButton>
        </Upload>
      </Form.Item>

      {!!beforeUploadError && (
        <Alert type="error" message={beforeUploadError} />
      )}
    </>
  )
}

export default FileUpload
