import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./index.scss"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "styled-components"
import { theme } from "./components/styled/ThemeProvider"
import client from "./utils/apolloClient"
import { ConfigProvider } from "antd"

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "Mulish, sans-serif",
              },
            }}
          >
            <App />
          </ConfigProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
