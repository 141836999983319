import userImg from "../../assets/images/account_circle.svg"
import { Divider } from "antd"
import { useQuery } from "@apollo/client"
import { GET_USER_STAT } from "../../graphql/queries"
import { useState } from "react"
import styled from "styled-components"
import { formatAmount } from "../../utils/date"
import { IUserStat, IUserStatsResponse } from "../../types/graphqlResponse"

const HighLightAmount = styled.div`
  color: ${({ theme }) => theme.colors.primaryColor};
  font-weight: bold;
  max-width: max-content;
  margin: auto;
`

const UserStats = () => {
  const [stat, setStat] = useState<IUserStat>()

  useQuery<IUserStatsResponse>(GET_USER_STAT, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setStat({
        ...data.userStats,
      })
    },
  })

  return (
    <>
      <div className="container-fluid pt-2 pt-md-0 my-2 small">
        <div className="row text-center text-break justify-content-center align-items-center">
          <div className="col-xl-2 col-md-3">
            <img
              src={userImg}
              width="20"
              height="20"
              className="align-bottom"
              alt="user"
            />
            <span className="fw-bold ps-2">{stat?.name || "Unknown"}</span>
          </div>
          <div className="col-xl-4 col-md-3">
            <div className="row">
              <div className="col-xl-6">{stat?.email || "Email Address"}</div>
              {/* <div className="col-xl-6">
                {stat?.dealerName || "Dealer Name"}
              </div> */}
            </div>
          </div>
          <div className="col-md-6 col-12 d-block d-md-none">
            <Divider className="my-3" />
          </div>
          <div className="col-md-6 col-12 border-start">
            <table width="100%" className="text-center">
              <tbody>
                <tr>
                  <th rowSpan={2}>Rewards Earned:</th>
                  <td>This Month:</td>
                  <td>Last Month:</td>
                  <td>Year to Date:</td>
                </tr>
                <tr>
                  <td>
                    <HighLightAmount>
                      {formatAmount(stat?.earnedThisMonth)}
                    </HighLightAmount>
                  </td>
                  <td>
                    <HighLightAmount>
                      {formatAmount(stat?.earnedLastMonth)}
                    </HighLightAmount>
                  </td>
                  <td>
                    <HighLightAmount>
                      {formatAmount(stat?.earnedYearToDate)}
                    </HighLightAmount>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Divider className="my-0" />
    </>
  )
}

export default UserStats
