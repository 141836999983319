import { Spin, Form, Input } from "antd"
import { SIGNIN } from "../graphql/mutations"
import { useMutation } from "@apollo/client"
import { useNavigate, useLocation, Link } from "react-router-dom"
import Button from "../components/styled/Button.styled"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { useEffect } from "react"
import globals from "../constants/globals"
import { ISigninResponse } from "../types/graphqlResponse"
import { setAuthToken } from "../utils/auth"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { CommonPaths, ConsumerPaths } from "../constants/paths"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import HeroSection from "../components/styled/HeroSection.styled"

const { PROGRAM_NAME, IS_ADMIN } = globals

const SignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { loggedIn } = useAuthStatus()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH

  const [signinUser, { loading, error }] = useMutation(SIGNIN)
  const { loading: loadingPermissions } = useGetMyPermissions()

  const signIn = (values: { userName: string; password: string }) => {
    signinUser({
      variables: {
        ...values,
        signingInFromConsumerOrAdmin: IS_ADMIN ? "admin" : "consumer",
      },
      onCompleted: (data: ISigninResponse) => {
        setAuthToken({
          token: data.signIn.token,
          refresh_token: data.signIn.refreshToken,
        })
      },
      fetchPolicy: "network-only",
    })
  }

  useEffect(() => {
    if (loggedIn && !loadingPermissions) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, loggedIn, loadingPermissions])

  return (
    <>
      <Header />

      <div className="container-fluid py-5 vh-fill bg-sky3">
        <div className="row justify-content-center">
          <div className="col-xxl-3 col-xl-6 col-md-8 col-10 bg-white rounded shadow p-4">
            <div className="text-center fw-bold pb-4">
              <div className="text-secondary fs-5">Incentive Wizard</div>
              <h1 className="fw-bold text-uppercase">Sign In</h1>
            </div>
            <Form onFinish={signIn} layout="vertical" requiredMark={false}>
              <Form.Item
                name="userName"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {loading ? (
                <div className="row justify-content-center pt-1">
                  <div className="col text-center">
                    <Spin />
                  </div>
                </div>
              ) : (
                error && (
                  <div className="row pt-1">
                    <div className="col">
                      <div className="alert alert-danger">{error?.message}</div>
                    </div>
                  </div>
                )
              )}
              <div className="row pt-3">
                <div className="col text-center">
                  <Button type="submit">Sign In</Button>
                </div>
              </div>
            </Form>

            <div className="row pt-4 text-center">
              <div className="col">
                <Link
                  to={CommonPaths.FORGOT_PASSWORD_PATH}
                  className="custom-link mx-2"
                >
                  Forgot Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SignIn
